import { useEffect, useState } from 'react';
import styled from 'styled-components';

export const ProgressBar = ({progress, delivery}) => {

    function reformatDate(inputDate) {
        const dateParts = inputDate?.split('-');
        if (dateParts?.length === 3) {
          const year = dateParts[2];
          const month = dateParts[1];
          const day = dateParts[0];
          return `${year}-${month}-${day}`;
        }
        return inputDate;
      }
      const formattedDelivery = reformatDate(delivery);
      const deliveryDate = new Date(formattedDelivery?.replace(/-/g, '/'));

     const months = [
       'Janeiro',
       'Fevereiro',
       'Março',
       'Abril',
       'Maio',
       'Junho',
       'Julho',
       'Agosto',
       'Setembro',
       'Outubro',
       'Novembro',
       'Dezembro',
     ];

     const month = months[deliveryDate.getMonth()];
     const year = deliveryDate.getFullYear();

  return (
    <div className="ProgressBar">
        <Container>
            <Content>
                <Label>Previsão de Entrega: <span>{`${month} de ${year}`}</span></Label>
                <Progress width={progress}><span></span></Progress>
                <Desc>Andamento da Obra: <span>{progress}%</span></Desc>
            </Content>
        </Container>
    </div>
  );
}

const Container = styled.div`
    width:100%;
    padding:80px 0;
    background-color:#D9D9D9;

    @media(max-width:768px){
        padding:40px 20px;
        width:calc(100% - 40px);
    }
`;

const Content = styled.div`
    max-width:960px;
    margin:auto;
`;

const Label = styled.div`
    font-size:18px;
    font-family:'OpenSans', sans-serif;
    color:#101010;

    span{
        font-weight:600;
    }
`;

const Progress = styled.div`
    width:100%;
    height:9px;
    border-radius:5px;
    background-color:#cdcdcd;
    margin:20px 0;
    position:relative;

    span{
        content:'';
        position:absolute;
        left:0;
        height:9px;
        width:${props => props.width}%;
        background-color:#6799A4;
        top:0;
        bottom:0;
        border-radius:5px;
        z-index:1;
    }
`;

const Desc = styled.div`
    font-size:18px;
    font-family:'OpenSans', sans-serif;
    color:#101010;
    text-align:right;

    span{
        font-weight:600;
    }
`;