import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {IoLogoWhatsapp} from 'react-icons/io';
import {AiOutlineMail} from 'react-icons/ai';
import {BsWhatsapp} from 'react-icons/bs';

export const UserBar = ({user}) => {

    function formatPhoneNumber(phoneNumber) {
        // Remove todos os caracteres não numéricos
        const cleaned = phoneNumber?.replace(/\D/g, '');
        
        // Verifica o comprimento da string e aplica a formatação adequada
        if (cleaned?.length === 11) {
            return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
        } else if (cleaned?.length === 10) {
            return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 6)}-${cleaned.slice(6)}`;
        } else {
            return phoneNumber; // Formato inválido, retorna a string original
        }
    }

  return (
    <div className="UserBar">
        <Container>
            <Title>Ficou interessado? Entre em contato.</Title>
            <Content>
                <Profile>
                    <Cover cover={user?.profile_photo}></Cover>
                    <Name>{user?.name}</Name>
                    <Creci>CRECI: {user?.creci}</Creci>
                </Profile>
                <Info>
                    <Phone><IoLogoWhatsapp/>{formatPhoneNumber(user?.phone)}</Phone>
                    <Email><AiOutlineMail/>{user?.email}</Email>
                    <a href={`https://wa.me/55${user?.phone}`} target="_blank"><Whats><BsWhatsapp/>Chamar no Whatsapp</Whats></a>
                </Info>
            </Content>
        </Container>
    </div>
  );
}

const Container = styled.div`
    max-width:760px;
    margin:auto;
    padding:40px 0 80px;

    @media(max-width:768px){
        padding:0px 20px 40px;
    }
`;

const Title = styled.div`
    text-align:center;
    font-family:'OpenSans', sans-serif;
    font-size:24px;
    font-weight:600;
    color:#101010;
`;

const Content = styled.div`
    width:calc(100% - 80px);
    padding:40px;
    box-shadow:0 0 5px rgba(0,0,0,0.5);
    margin:30px 0 0;
    border-radius:8px;
    display:flex;
    justify-content:space-between;

    @media(max-width:768px){
        flex-direction:column;
    }
`;

const Profile = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    gap:10px;
`;

const Cover = styled.div`
    width:120px;
    height:120px;
    border-radius:50%;
    background-color:#cdcdcd;
    background-image:url(${props => props.cover});
    background-position:center center;
    background-size:cover;
`;

const Name = styled.div`

`;

const Creci = styled.div`

`;

const Info = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    gap:20px;
    @media(max-width:768px){
        margin-top:20px;
    }
`;

const Phone = styled.div`
    width:200px;
    height:37px;
    border:solid 1px #D9D9D9;
    border-radius:5px;
    display:flex;
    gap:10px;
    align-items:center;
    padding:0 20px;
    color:#333;
`;

const Email = styled.div`
    width:200px;
    height:37px;
    border:solid 1px #D9D9D9;
    border-radius:5px;
    display:flex;
    gap:10px;
    align-items:center;
    padding:0 20px;
    color:#333;
`;

const Whats = styled.div`
    width:200px;
    height:37px;
    border-radius:5px;
    background-color:#2C512B;
    display:flex;
    gap:10px;
    align-items:center;
    padding:0 20px;
    color:#fff;
    cursor:pointer;
`;