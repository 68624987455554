import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import { MdOutlinePlace } from "react-icons/md";

// import { Icon } from '@iconify/react'
// import locationIcon from '@iconify/icons-mdi/map-marker'
// import icon from './local-maps.png';

// import '../src/components/Map/Map.css';


  const LocationPin = ({ showMap }) => (
  showMap == '0' || showMap == null || showMap == '' ? (
    <div>
      <Raio></Raio>
    </div>
  ) : (
    <Pin>
      <MdOutlinePlace/>
    </Pin>
  )
)


// const location = {
//   lat: lat,
//   lng: lng,
//   // lat: 1,
//   // lng: 2,
// }https://sort.vps-kinghost.net/api/select/immobile/' + id



  const Maps = ({ latI, lngI, zoomLevel, showMap }) => (

  < div className="map" >
    <MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBswLCO_NJ1ZPqCfxFr6aNX0pyaw1SyhvM' }}
        defaultCenter={{ lat: parseFloat(latI), lng: parseFloat(lngI) }}
        defaultZoom={zoomLevel}
      >
        <LocationPin
          lat={parseFloat(latI)}
          lng={parseFloat(lngI)}
          showMap={showMap}
        />
      </GoogleMapReact>
    </MapContainer>
  </div >
)

export default Maps;

const MapContainer = styled.div`
  width: 100%;
  height:380px;

  @media screen and (min-width: 799px) {
    height: 540px;
  }
`;

const Raio = styled.div`
    width: 240px;
    height: 240px;
    background-color: #2f734c41;
    border: solid 1px #2f734c;
    border-radius: 50%;
    margin-left: -120px;
    margin-top: -120px;
`;

const Pin = styled.div`
    display: flex;
    align-items: center;
    width: 180px;
    margin-top:-20px;
    margin-left:-20px;
    font-size:40px;

    @media(min-width: 799px){
        width: 15vw;
    }
`;