import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import styled from 'styled-components';
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Fancybox from './Fancybox';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

import { Navigation } from 'swiper/modules';

export const SlidesBar = ({photos, videos, plans, bg}) => {

    const [active, setActive] = useState('fotos')

    const swiper = useSwiper();

    useEffect(() => {
        NativeFancybox.bind("[data-fancybox='gallery']", {});
        
        return () => {
            NativeFancybox.destroy();
        };
    }, []);

    const handlePrevClick = () => {
        if (swiper) {
            swiper.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (swiper) {
            swiper.slideNext();
        }
    };

  return (
    <div className="SlidesBar">
        <Container bg={bg}>
            <div className="bg"></div>
            <Title>Galeria do empreendimento</Title>
            <ContainerButton>
                <Button className={active === 'fotos' && 'active'} onClick={() => setActive('fotos')}>Fotos</Button>
                <Button className={active === 'videos' && 'active'} onClick={() => setActive('videos')}>Vídeos</Button>
                <Button className={active === 'plantas' && 'active'} onClick={() => setActive('plantas')}>Plantas</Button>
            </ContainerButton>
            <ContainerSwiper>
                <Swiper
                    navigation={{
                        prevEl: '.custom-prev-button',
                        nextEl: '.custom-next-button',
                    }}
                    modules={[Navigation]}
                    loop={true}
                    slidesPerView={1}
                    spaceBetween={40}
                    keyboard={{
                        enabled: true,
                      }}
                    className="mySwiper"
                >
                    {active === 'fotos' &&
                        photos?.map((photo, index) => (
                            <SwiperSlide key={index}>
                                <Fancybox options={{ infinite: true }} delegate="[data-fancybox='gallery']">
                                    <img data-fancybox="gallery" src={photo.extra_image}/>
                                </Fancybox>
                            </SwiperSlide>
                        ))
                    }
                    {active === 'plantas' &&
                        plans?.map((plan, index) => (
                            <SwiperSlide key={index}>
                                <Fancybox options={{ infinite: true }} delegate="[data-fancybox='gallery']">
                                    <img data-fancybox="gallery" src={plan.url}/>
                                </Fancybox>    
                            </SwiperSlide>
                        ))
                    }
                    {active === 'videos' &&
                        videos?.map((video, index) => (
                            <SwiperSlide key={index}>
                                <video controls>
                                    <source src={video.url} type="video/mp4"></source>
                                    Seu navegador não suporte vídeos.
                                </video>
                            </SwiperSlide>
                        ))
                    }
                <CustomNavButton onClick={handlePrevClick} className="custom-prev-button"><SlArrowLeft size="3.5rem"/></CustomNavButton>
                <CustomNavButton onClick={handleNextClick} className="custom-next-button"><SlArrowRight size="3.5rem"/></CustomNavButton>
                </Swiper>
            </ContainerSwiper>
        </Container>
    </div>
  );
}

const Container = styled.div`
    padding:80px 0;
    position:relative;

    & .bg{
        background-size:cover;
        background-position:center center;
        background-image:url(${props => props.bg});
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        width:100%;
        height:100%;
        z-index:-1;
        opacity:0.3;
        filter: grayscale(100%);
    }

    @media(max-width:768px){
        padding:60px 0 40px;
    }
`;

const Title = styled.div`
    text-align:center;
    font-family:'OpenSans', sans-serif;
    font-size:24px;
    font-weight:600;
    color:#101010;
`;

const ContainerSwiper = styled.div`
    margin:40px auto;
    max-width:760px;
    width:100%;

    img{
        width:100%;
        height:460px;
        object-fit:cover;
    }

    .swiper-horizontal{
        padding: 0 40px;
    }

    video{
        width:100%;
        height:460px;
    }

    @media(max-width:768px){
        img{
            height:260px;
        }
        video{
            height:260px;
        }
    }
`;

const ContainerButton = styled.div`
    display:flex;
    gap:20px;
    align-items:center;
    justify-content:center;
    margin:30px 0;
`;

const Button = styled.div`
    background-color:#cdcdcd;
    height:42px;
    width:100px;
    display:flex;
    align-items:center;
    justify-content:center;
    color:#101010;
    font-weight:600;
    border-radius:8px;
    cursor:pointer;
    border:solid 1px #888;

    &.active, &:hover{
        background-color:#333;
        color:#fff;
    }
`;

const CustomNavButton = styled.div`
    color: #333;
    font-size: 24px;
    width:45px;
    height:45px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 50%;
    cursor: pointer;
    z-index:99;
    position: absolute;
    top: 45%;
    transform: translateY(-10%);

    &.custom-next-button{
        right:0px;
    }

    &.custom-prev-button{
        left:0px;
    }

    @media(max-width:768px){
        width:40px;
        height:40px;
        opacity:0.8;
    }
`;
