import styled from 'styled-components';

export const Footer = ({logo}) => {

  return (
    <div className="Footer">
        <Container>
            <Content>
                <Logo image={logo}></Logo>
                <Menu>
                    <li><a  href="#home">Home</a></li>
                    <li><a  href="#caracteristicas">Características</a></li>
                    <li><a  href="#galeria">Galeria</a></li>
                    <li><a  href="#localizacao">Localização</a></li>
                    <li><a  href="#contato">Contato</a></li>
                </Menu>
            </Content>
        </Container>
        <EndBar></EndBar>
    </div>
  );
}

const Container = styled.div`
    margin-top:30px;
    padding:40px 0;
    background-color:#6A6A6A;
`;

const EndBar = styled.div`
    height:60px;
    background-color:#4D4D4D;
    width:100%;
`;

const Content = styled.div`
    max-width:760px;
    margin:auto;
    display:flex;
    gap:40px;
    align-items:center;

    @media(max-width:768px){
        flex-direction:column;
        width:100%;
        gap:20px;
    }
`;

const Logo = styled.div`
    flex:2;
    background-image:url(${props => props.image});
    background-size:contain;
    background-position:center center;
    height:180px;
    width:100%;
    background-repeat:no-repeat;

    @media(max-width:768px){
        width:80%;
        margin:auto;
        flex:auto;
    }
`;

const Menu = styled.div`
    flex:1;
    li{
        border-bottom:solid 1px #fff;
        padding:5px 0;
        list-style:none;
        max-width:140px;
        color:#fff;
        margin-bottom:8px;
        cursor:pointer;
    }

    li:hover{
        color:#101010;
        border-bottom:solid 1px #101010;
    }

    @media(max-width:768px){
        flex:auto;
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        text-align:center;

        li{
            width:180px;
            max-width:180px;
        }
    }
`;