import axios from 'axios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {BiShareAlt} from 'react-icons/bi';
import {RiGalleryFill} from 'react-icons/ri';
import {IoIosPin} from 'react-icons/io';
import {GoProjectTemplate} from 'react-icons/go';
import { ProgressBar } from './components/progressBar';
import { SkillsBar } from './components/skills';
import { SlidesBar } from './components/slides';
import { MapBar } from './components/map';
import { UserBar } from './components/user';
import { Footer } from './components/footer';

export default function App() {

  const[enterprise, setEnterprise] = useState([])

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const slug = urlParts[3];

    const url = new URL(currentUrl);

    const id = url.searchParams.get("id");
    const type = url.searchParams.get("type");

    axios.post('https://dev.fastsaleimoveis.com.br/api/public/property-enterprises-slug/' + slug, {
      user_id: id,
      type: type
    })
      .then(response => setEnterprise(response.data))

  },[])

  return (
    <div className="App">
      <div id="home"><Banner image={enterprise?.photos?.filter(photo => photo.order === 0)[0].extra_image}>
        <Title>{enterprise?.title}</Title>
        <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(window.location.href)}`} target="_blank"><Button>Compartilhe<BiShareAlt size="1.5rem"/></Button></a>
        <ContainerAncor>
          <Item href="#galeria">
            <Image><RiGalleryFill size="1.5rem"/></Image>
            <Label>Galeria</Label>
          </Item>
          <Item href="#localizacao">
            <Image><IoIosPin size="1.5rem"/></Image>
            <Label>Localização</Label>
          </Item>
          <Item href="#galeria">
            <Image><GoProjectTemplate size="1.5rem"/></Image>
            <Label>Plantas</Label>
          </Item>
        </ContainerAncor>
      </Banner></div>
      <ProgressBar delivery={enterprise?.delivery} progress={enterprise?.progress}/>
      <div id="caracteristicas"><SkillsBar skills={enterprise?.details}/></div>
      <div id="galeria"><SlidesBar
        videos={enterprise?.videos}
        plans={enterprise?.plans}
        photos={enterprise?.photos}
        bg={enterprise?.photos?.filter(photo => photo.order === 0)[0].extra_image}
      /></div>
      <div id="localizacao"><MapBar
        street={enterprise?.street}
        city={enterprise?.city}
        state={enterprise?.state}
        number={enterprise?.number}
        latitude={enterprise?.latitude}
        longitude={enterprise?.longitude}
      /></div>
      <div id="contato"><UserBar user={enterprise?.user}/></div>
      <Footer logo={enterprise?.logo}/>
    </div>
  );
}

const Banner = styled.div`
  background-image: url(${props => props.image});
  background-size:cover;
  background-position:center center;
  height:820px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  gap:80px;

  @media(max-width:768px){
    height:480px;
  }
`;

const Title = styled.div`
  font-size:42px;
  color:#fff;
  text-shadow:0 0 5px rgba(0,0,0,0.5);
  font-weight:600;
  font-family:'OpenSans', sans-serif;

  @media(max-width:768px){
    font-size:26px;
    text-align:center;
  }
`;

const Button = styled.div`
  width:190px;
  height:42px;
  margin:0 auto;
  background-color:#6799A4;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff;
  font-family:'OpenSans', sans-serif;
  cursor:pointer;
  transition:0.2s;
  font-weight:600;
  gap:15px;
  border-radius:6px;
  box-shadow:0 0 5px rgba(0,0,0,0.5);

  &:hover{
    transform:scale(1.02);
  }
`;

const ContainerAncor = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  max-width:480px;
  margin:0 auto;
  gap:40px;
`;

const Item = styled.a`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  gap:10px;
  cursor:pointer;
  transition:0.2s;

  &:hover{
    transform:scale(1.02);
  }
`;

const Image = styled.div`
  background-color:#D9D9D9;
  height:90px;
  width:90px;
  border-radius:50%;
  display:flex;
  align-items:center;
  justify-content:center;
  box-shadow:0 0 5px rgba(0,0,0,0.5);

  @media(max-width:768px){
    width:65px;
    height:65px;
  }
`;

const Label = styled.div`
  color:#fff;
  font-weight:600;
  text-shadow:0 0 5px rgba(0,0,0,0.5);
`;