import { useEffect, useState } from 'react';
import styled from 'styled-components';

export const SkillsBar = ({skills}) => {



  return (
    <div className="SkillsBar">
        <Container>
            <Title>Características</Title>
            <SubTitle>Empreendimento</SubTitle>
            <ContainerSkills>
                {skills?.map((skill, index) => 
                    <li key={index}>{skill.detail}</li>
                )}
            </ContainerSkills>
        </Container>
    </div>
  );
}

const Container = styled.div`
    max-width:960px;
    margin:auto;
    padding:80px 0;

    @media(max-width:768px){
        padding:40px 20px 0;
    }
`;

const Title = styled.div`
    text-align:center;
    font-family:'OpenSans', sans-serif;
    font-size:24px;
    font-weight:600;
    color:#101010;
`;

const SubTitle = styled.div`
    text-align:center;
    font-family:'OpenSans', sans-serif;
    font-size:18px;
    font-weight:500;
    color:#101010;
`;

const ContainerSkills = styled.div`
    margin:40px 0;
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));

    li{
        font-family:'OpenSans', sans-serif;
        list-style:none;
        display:flex;
        align-items:center;
        gap:10px;
        padding:8px 0;
        color:#101010;

        &::before{
            display:flex;
            content:'';
            width:8px;
            height:8px;
            background-color:#cdcdcd;
            border-radius:50%;
        }
    }

    @media(max-width:768px){
        grid-template-columns: 1fr 1fr;
    }
`;