import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Maps from './maps';

export const MapBar = ({street, city, state, number, latitude, longitude}) => {

  return (
    <div className="MapBar">
        <Container>
            <Content>
                <Title>Localização</Title>
                {(latitude && longitude) &&
                    <Maps latI={latitude} lngI={longitude} zoomLevel={16} showMap={false}/>
                }
            </Content>
        </Container>
    </div>
  );
}

const Container = styled.div`
    padding:80px 0 0;
    position:relative;

    &::before{
        content:'';
        width:100%;
        top:100px;
        bottom:350px;
        background-color:#D9D9D9;
        position:absolute;
        z-index:-1;
    }
    @media(max-width:768px){
        margin-top:-60px;
    }

`;

const Content = styled.div`
    max-width:960px;
    margin:auto;
    padding:80px 0;

    @media(max-width:768px){
        padding:80px 20px;
    }
`;

const Title = styled.div`
    text-align:center;
    font-family:'OpenSans', sans-serif;
    font-size:24px;
    font-weight:600;
    color:#101010;
    margin-bottom:30px;
`;
